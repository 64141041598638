import { keys, without } from 'lodash-es'

// HTTP status codes
export const OK = 200
export const CREATED = 201
export const NO_CONTENT = 204
export const BAD_REQUEST = 400
export const FORBIDDEN = 401
export const NOT_FOUND = 404
// types of fabric
export const EXISTING_VADAIN_FABRICS = 'EXISTING_VADAIN_FABRICS'
export const EXISTING_CUSTOMER_FABRICS = 'EXISTING_CUSTOMER_FABRICS'
export const NEW_CUSTOMER_FABRIC = 'NEW_CUSTOMER_FABRIC'
export const USER = 'USER'
// call list options to be used with user fabrics
export const SUPPLIER = 'supplier'
export const DRIVER = 'driver'
export const VADAIN_GENEMUIDEN = 'vadaingenemuiden'
export const VADAIN_HELMOND = 'vadainhelmond'
// make sure the FABRICS and LININGS values are equal to their state modules
export const FABRICS = 'fabrics'
export const LININGS = 'linings'
// fabric types
export const MAIN_FABRIC = 'Main fabric'
export const LINING_FABRIC = 'Lining fabric'
// article types
export const CURTAIN = 'curtain'
export const RAIL_ROD = 'railRod'
// control types
export const CHAIN = 'chain'
export const DELIVER_WITH_CONTROL_ROD = 'deliverwithcontrolrod'
// pattern types
export const PLAIN = 'plain'
export const PATTERN = 'pattern'
// curtain types
export const RING_CURTAIN = 'ringcurtain'
export const FOLD_CURTAIN = 'foldcurtain'
export const CUTYARDAGE = 'cutyardage'
export const PANEL_CURTAIN = 'panelcurtain'
export const CUSHION = 'cushion'
export const RMA = 'rma'
export const TIEBACK = 'tieback'
//luxury types
export const BASIC = 'basic'
export const COMFORT = 'comfort'
export const HAUTE_COUTURE = 'hautecouture'
// transparency type
export const SHEER = 'sheer'
export const TRANSLUCENT = 'translucent'
export const DARKENING = 'darkening'
// order move and split
export const NEW_COMMISSION = 'new-commission'
// order type
export const DRAFT = 'draft'
export const OFFERED = 'offered'
// required parameters for curtain price calculation or curtain order,
// depending on the curtain type
export const REQUIRED_CURTAIN_PARAMETERS = {
  pleatcurtain: [
    'main_fabric',
    'height',
    'width',
    'split_type',
    'hem_type',
    'luxury',
    'pleat_type'
  ],
  foldcurtain: ['main_fabric', 'height', 'width', 'fold_type', 'mounting_system'],
  fronslintcurtain: [
    'main_fabric',
    'height',
    'width',
    'split_type',
    'hem_type',
    'luxury',
    'fronslint_type'
  ],
  rodcurtain: [
    'main_fabric',
    'height',
    'width',
    'split_type',
    'hem_type',
    'luxury',
    'pleat_type',
    'rod_size'
  ],
  spancurtain: [
    'main_fabric',
    'height',
    'width',
    'split_type',
    'luxury',
    'pleat_type',
    'rod_size'
  ],
  ringcurtain: [
    'main_fabric',
    'height',
    'width',
    'split_type',
    'luxury',
    'ring_size',
    'ring_type'
  ],
  [TIEBACK]: ['main_fabric', 'length', 'tieback_type', 'quantity'],
  loopcurtain: [
    'main_fabric',
    'height',
    'width',
    'split_type',
    'hem_type',
    'loop_width',
    'loop_length'
  ],
  panelcurtain: ['main_fabric', 'height', 'width', 'panel_type', 'number_of_panels'],
  [CUTYARDAGE]: ['main_fabric', 'sizes'],
  wavecurtain: ['main_fabric', 'height', 'width', 'split_type', 'hem_type', 'luxury'],
  cushion: ['main_fabric', 'cushion_type', 'length', 'width', 'quantity']
  // add more curtain types here
}
// rail types
export const TRACK_RAIL = 'trackrail'
export const DECORATIVE_RAIL = 'decorativerail'
export const ELECTRIC_RAIL_GENERAL = 'electricrail'
export const ELECTRIC_RAIL_BASIC = 'electrictrackrail'
export const ELECTRIC_RAIL_DECORATIVE_R35MM = 'eraildecoround35mm'
export const ELECTRIC_RAIL_DECORATIVE_S32MM = 'eraildecosquare32mm'
// rod types
export const DECORATIVE_ROD = 'decorativerod'
export const PULL_ROD = 'pullrodvako'
// rail rod parts
export const RAIL_ROD_PARTS = 'accessory-parts'
export const RAIL_ROD_PARTS_ARTICLE_TYPE = 'vakoaccessoryparts'
// curtain types
export const PLEAT_CURTAIN = 'pleatcurtain'
export const WAVE_CURTAIN = 'wavecurtain'

// required parameters for rail rod price calculation or rail rod order,
// depending on the rail rod type
export const REQUIRED_RAIL_ROD_PARAMETERS = {
  [TRACK_RAIL]: ['color', 'track_type', 'support_type'],
  [DECORATIVE_RAIL]: ['color', 'rail_type', 'support_type'],
  [ELECTRIC_RAIL_BASIC]: ['color', 'support_type'],
  [ELECTRIC_RAIL_DECORATIVE_R35MM]: ['color', 'support_type'],
  [ELECTRIC_RAIL_DECORATIVE_S32MM]: ['color', 'support_type'],
  [DECORATIVE_ROD]: ['color', 'rod_size', 'support_type']
}
export const RAIL_RUNNER_PARAMETERS = ['runner_type', 'runner_color']
export const REQUIRED_PULL_ROD_PARAMETERS = ['color', 'length']
// the array of curtain types that are allowed to get a rail or rod
export const RAIL_ROD_CURTAIN_TYPES = [
  'pleatcurtain',
  'wavecurtain',
  'fronslintcurtain',
  'ringcurtain'
]
// the array of all curtain types that are available
export const CURTAIN_TYPES = keys(REQUIRED_CURTAIN_PARAMETERS)
// the array of all curtain types that can be used for sets
export const SET_CURTAIN_TYPES = without(CURTAIN_TYPES, 'cushion', CUTYARDAGE, TIEBACK)
// the array of all curtain types that can be rounded to pattern
export const ROUND_TO_PATTERN_CURTAIN_TYPES = [CUTYARDAGE, TIEBACK]
// the array of all decorative rail types
export const DECORATIVE_RAIL_TYPES = [
  DECORATIVE_RAIL,
  DECORATIVE_ROD,
  ELECTRIC_RAIL_DECORATIVE_S32MM,
  ELECTRIC_RAIL_DECORATIVE_R35MM
]
// the array of all electric rail rod types
export const ELECTRIC_RAIL_TYPES = [
  ELECTRIC_RAIL_BASIC,
  ELECTRIC_RAIL_DECORATIVE_S32MM,
  ELECTRIC_RAIL_DECORATIVE_R35MM
]
// the array of all electric decorative rail rod types
export const ELECTRIC_DECORATIVE_RAIL_TYPES = [
  ELECTRIC_RAIL_DECORATIVE_S32MM,
  ELECTRIC_RAIL_DECORATIVE_R35MM
]
// the array of all rail types that should get asymmetric runners
export const ASYMMETRIC_RUNNER_RAIL_TYPES = [...ELECTRIC_RAIL_TYPES, DECORATIVE_RAIL]
// the array of all rail rod types that are available
export const RAIL_ROD_TYPES = [
  TRACK_RAIL,
  DECORATIVE_RAIL,
  DECORATIVE_ROD,
  ...ELECTRIC_RAIL_TYPES
]
export const NON_ELECTRIC_RAIL_TYPES = [TRACK_RAIL, DECORATIVE_RAIL]
export const RAIL_TYPES = [...NON_ELECTRIC_RAIL_TYPES, ...ELECTRIC_RAIL_TYPES]
// the array of rail colors with different minimum connector widths
export const RAIL_ROD_CONNECTOR_DIFFERENT_MIN_WIDTH_COLORS = [
  'nickelbrushed',
  'goldbrushed',
  'rosecopper'
]
export const UNSUPPORTED_PULL_ROD_CURTAIN_TYPES = [
  CUSHION,
  CUTYARDAGE,
  FOLD_CURTAIN,
  RING_CURTAIN,
  TIEBACK
]

export const PRODUCTS_WITHOUT_ROOM_SIDE = [TIEBACK, CUTYARDAGE, 'cushion']

// colors
export const CHAMPAGNE = 'champagne'
export const NICKEL_BRUSHED = 'nickelbrushed'
export const GOLD_BRUSHED = 'goldbrushed'
export const ROSE_COPPER = 'rosecopper'
// support types
export const INSIDE_FRAME_SUPPORT = 'insideframe'
export const WALL_SUPPORT = 'wall'
export const CEILING_SUPPORT = 'ceiling'
// track types
export const TRACK_1014 = 'track_1014'
export const TRACK_1019 = 'track_1019'
// split types
export const LEFT = 'left'
export const RIGHT = 'right'
export const EQUAL = 'equal'
export const UNEQUAL = 'unequal'
// runner distances
export const RUNNER_46MM = '4.6'
export const RUNNER_6CM = '6'
export const RUNNER_8CM = '8'
// ring sizes
export const RING_SIZE_25MM = 'ringsize25mm'

export const CURTAIN_DATA_LOAD_WAITER = 'loadingCurtainFlowData'
export const RAILROD_DATA_LOAD_WAITER = 'loadingRailRodFlowData'
export const PULLROD_DATA_LOAD_WAITER = 'loadingPullRodFlowData'

export const FLOW_MAP_TABLES = {
  curtain: {
    curtainTypeStep: ['luxury'],
    measurementsStep: [
      'width',
      'height',
      'return_left',
      'return_right',
      'overlap_left',
      'overlap_right',
      'assembly_height',
      'main_fabric_width_type',
      'side_option',
      'panel_type',
      'number_of_panels',
      'tieback_type',
      'length',
      'quantity',
      'sizes'
    ],
    detailsStep: [
      'pleat_type',
      'split_type',
      'pleat_percentage',
      'inverted_pleat',
      'tier_size',
      'head_size',
      'main_fabric_left_strokes',
      'main_fabric_right_strokes',
      'match_strokes',
      'left_side_of_width',
      'right_side_of_width',
      'loop_width',
      'loop_length',
      'loop_with_knots',
      'runner_distance',
      'wave_length',
      'wave_hook_color',
      'deliver_with_runners',
      'runner_color',
      'fronslint_type',
      'nap_orientation'
    ],
    finishStep: [
      'hem_type',
      'hem_size',
      'cutting',
      'weight',
      'rod_size',
      'ironing',
      'side_hem_type',
      'has_bottom_bars',
      'has_volant',
      'with_washing_label',
      'with_pre_washing',
      'hide_seams',
      'blockout_seams',
      'has_side_guide',
      'with_child_safety',
      'ring_size',
      'ring_type',
      'other_ring_type',
      'ring_rubber',
      'box_delivery'
    ],
    curtainMemoStep: ['order_memo'],
    liningStep: ['lining_fabric', 'lining_fabric_width_type'],
    serviceStep: [
      'mounting_system',
      'control_type',
      'chain_color',
      'control_side',
      'control_length',
      'has_cover_flap'
    ],
    electricOptionsStep: [
      'electric_option',
      'battery_option',
      'with_remote_control',
      'with_domotica_hub'
    ]
  },
  railrod: {
    railCurtainType: ['rail_curtain_type'],
    railRodSplitTypeStep: ['curtain_split_type'],
    railTypeStep: ['curtain_rail_type'],
    railRodModelStep: ['rail_type', 'rod_size', 'track_type'],
    railRodColorStep: ['color'],
    railRodCurveStep: ['curve_type'],
    railRodDimensionStep: [
      'width',
      'curve_left_length',
      'curve_right_length',
      'curve_center_length',
      'curve_left_edge_length',
      'curve_right_edge_length'
    ],
    railRodMotor: [
      'power_type',
      'motor_color',
      'motor_side',
      'with_remote_control',
      'with_hub'
    ],
    railRodSupportType: ['support_type', 'additional_support_type'],
    railRodSupportSize: ['support_size', 'additional_support_size'],
    railRodEndKnob: [
      'end_knob',
      'with_inside_frame_end_knob',
      'inside_frame_end_knob_position'
    ],
    railRunners: ['with_runner', 'runner_color', 'runner_type', 'wave_runner_distance'],
    railStopper: ['with_stopper'],
    railRetarder: ['with_retarder'],
    railRodMemoStep: ['order_memo']
  },
  pullrod: {
    pullRodColorStep: ['color'],
    pullRodLengthStep: ['length']
  },
  fabric: {
    generalDataStep: [
      'name',
      'supplier_id',
      'type',
      'pattern_type',
      'pattern_distance',
      'pattern_width',
      'width_type',
      'width',
      'is_turnable',
      'has_weightlace',
      'front_side',
      'back_side',
      'iron_property'
    ],
    colorsStep: ['article_number', 'color', 'user_image'],
    pricesStep: ['wholesale_price', 'price'],
    washingSymbolsStep: [
      'wash_property',
      'bleach_property',
      'dry_property',
      'clear_property',
      'is_flame_retardant',
      'is_recycled',
      'ecoCertificate'
    ],
    fabricCompositionStep: [
      'ac',
      'co',
      'div',
      'li',
      'lrx',
      'ma',
      'pa',
      'pe',
      'se',
      'tr',
      'vi',
      'wol',
      'total',
      'shrinkage_value'
    ],
    confectionsStep: ['curtain_types']
  }
}

export const SINGLE_NON_EDITABLE_ARTICLES = [PULL_ROD]
export const TOKEN_LIFE_TIMEOUT = 10 * 60 * 1000 // 10 minutes for token background refreshing
export const RAIL_ROD_AFTER_TEMPLATE_EVENT = 'railrod-template-updated'
export const CURTAIN_AFTER_TEMPLATE_EVENT = 'curtain-template-updated'
export const PULL_ROD_AFTER_TEMPLATE_EVENT = 'pullrod-template-updated'

export const AUSTRALIAN_GROUP_NAME = 'Australian'
export const DEFAULT_GROUP_NAME = 'Default'
export const POLISH_GROUP_NAME = 'Polish'
export const ANWIS_GROUP_NAME = 'Anwis'
export const OBI_POLSKA_GROUP_NAME = 'OBI Polska'

// downloads for the Downloads page
export const DOWNLOAD_CATEGORY_PRICE_LISTS = 'price-lists'

// nl dowloads
export const NL_DOWNLOADS = {
  general: [
    {
      title: 'Algemene verkoop- en leveringsvoorwaarden',
      url: '/documents/nl/general/AlgemeneVoorwaardenVadain.pdf'
    },
    {
      title: 'Handleiding webatelier',
      url: '/documents/nl/general/Gebruikershandleidingwebateliersept2009.pdf'
    },
    {
      title: 'VNGA Handleiding',
      url: '/documents/nl/general/VNGAHandleiding.pdf'
    },
    {
      title: 'Intentieverklaring consumententevredenheid',
      url: '/documents/nl/general/IntentieverklaringConsumenttevredenheidGordijnstoffen.pdf'
    },
    {
      title: 'Service als een geoliede machine',
      url: '/documents/nl/general/Service als een geoliede machine.pdf'
    },
    {
      title: 'Kindveiligheid',
      url: '/documents/nl/general/A5 flyer_voor in de doos.2.pdf'
    },
    {
      title: 'Brochure Inspiratie rails en roedes',
      url: '/documents/nl/general/Brochure_Inspiratie_rails_en_roedes.pdf'
    },
    {
      title: 'Rails en Roedes Technisch bladerboek Brochure',
      url: '/documents/nl/general/Rails_en_Roedes_Technisch_bladerboek_Brochure.pdf'
    },
    {
      title: 'Vadain E-rails Brochure',
      url: '/documents/nl/general/Vadain_E-rails_Brochure.pdf'
    },
    {
      title: 'Vadain Rails en Roedes Brochure',
      url: '/documents/nl/general/Vadain_Rails_en_Roedes_Brochure.pdf'
    },
    {
      title: 'Handleiding Elegant Rails',
      url: '/documents/nl/general/Vadain_Handleiding_Montage_Elegant_Rails.pdf'
    },
    {
      title: 'Handleiding E-rails',
      url: '/documents/nl/general/Handleiding_E-rails.pdf'
    },
    {
      title: 'Handleiding E-vouwgordijn',
      url: '/documents/nl/general/Handleiding_E-vouwgordijn.docx'
    }
  ],
  forms: [
    {
      title: 'Bestelbon concept',
      url: '/documents/nl/forms/BestelbonStofConfectie 2.pdf'
    },
    {
      title: 'Confectiebon',
      url: '/documents/nl/forms/confectiebon.pdf'
    },
    {
      title: 'Orderbon coupage',
      url: '/documents/nl/forms/OrderbonStofuitCollectieVadain.pdf'
    },
    {
      title: 'Bestelbon paneelgordijn',
      url: '/documents/nl/forms/Vadain Paneelgordijn Orderform.docx'
    },
    {
      title: 'Vouw-/paneelgordijnbon',
      url: '/documents/nl/forms/VouwPaneelgordijnbonStofAndereLeverancier.pdf'
    },
    {
      title: 'Brochure: Instore presentatiemeubels',
      url: '/documents/nl/forms/Brochure_Vadain_instore_digitaal.pdf'
    }
  ],
  [DOWNLOAD_CATEGORY_PRICE_LISTS]: [
    {
      title: 'Consumenten prijslijst per 1 februari 2025 - PDF',
      url: '/documents/nl/pricelists/Vadain Consumenten Prijslijst Web Feb 2025.pdf'
    },
    {
      title: 'Totaal prijslijst per 1 februari 2025 - Excel',
      url: '/documents/nl/pricelists/Vadain Totaal prijslijst per 1 februari 2025.xlsx'
    },
    {
      title: 'Totaal prijslijst per 1 februari 2025 - PDF',
      url: '/documents/nl/pricelists/Vadain Totaal prijslijst per 1 februari 2025.pdf'
    },
    {
      title: 'Prijslijst GEA per 25 maart 2024 - PDF',
      url: '/documents/nl/pricelists/Prijslijst GEA per 25 maart 2024.pdf'
    },
    {
      title: 'Uitlopende stoffen Vadain',
      url: '/documents/nl/pricelists/Uitlopende stoffen Vadain.pdf'
    },
    {
      title: 'Uitlopende stoffen Vadain else',
      url: '/documents/nl/pricelists/Uitlopende stoffen Vadain else.pdf'
    },
    {
      title: 'Voorraadlijst uitlopende stoffen Vadain en Else',
      url: '/documents/nl/pricelists/Voorraadlijst uitlopende stoffen Vadain en Else.xlsx'
    },
    {
      title: 'Vadain Paneelgordijnen',
      url: '/documents/nl/pricelists/Vadain Paneelgordijnen.pdf'
    }
  ],
  'promotion-materials': [
    {
      title: 'Vadain Outstanding Brochure',
      url: '/documents/nl/promotionmaterials/Vadain Brochure A5 Outstanding Web.pdf'
    },
    {
      title: 'Vadain else Future Flash Brochure',
      url: '/documents/nl/promotionmaterials/Vadain else 2024 Future Flash brochure final.pdf'
    },
    {
      title: 'Vadain Paradox Brochure',
      url: '/documents/nl/promotionmaterials/Paradox Brochure.pdf'
    },
    {
      title: 'Vadain Symbiotic Bliss brochure',
      url: '/documents/nl/promotionmaterials/Vadain Symbiotic Bliss brochure 2023 Web.pdf'
    },
    {
      title: 'Vadain Interconnected A5 Brochure',
      url: '/documents/nl/promotionmaterials/Vadain Interconnected A5.pdf'
    },
    {
      title: 'Vadain Interconnected Brochure Web',
      url: '/documents/nl/promotionmaterials/Vadain Interconnected Brochure Web.pdf'
    },
    {
      title: 'Vadain Re-think Supernatural Brochure Web',
      url: '/documents/nl/promotionmaterials/Vadain Re Think Supernatural Brochure Web.pdf'
    }
  ],
  'fabric-collection-images': [
    {
      title: "Dropbox Collectiefoto's",
      url: 'https://www.dropbox.com/scl/fo/83uuatut7f7xarskdh5k1/h?rlkey=wrn1jemjlteu3o9jffgfnvgmy&dl=0'
    }
  ]
}

export const NL_VIDEOS = [
  {
    title: 'Introductie op Easy Order',
    src: 'https://www.youtube.com/embed/dTM6FN2E-bo'
  },
  {
    title: "Overzicht instructievideo's",
    src: 'https://www.youtube.com/embed/AbBNAUybMos'
  },
  {
    title: 'Het in gebruik nemen en personaliseren van Easy Order',
    src: 'https://www.youtube.com/embed/C5-TC9Op-Pk'
  },
  {
    title: 'Gordijn samenstellen',
    src: 'https://www.youtube.com/embed/Rtk40zFdhaE'
  },
  {
    title: 'Vouwgordijn samenstellen',
    src: 'https://www.youtube.com/embed/viBBhn_ROQU'
  },
  {
    title: 'Rails en roedes bestellen',
    src: 'https://www.youtube.com/embed/l-7QE_E4U_M'
  },
  {
    title: 'Orders invoeren en bekijken',
    src: 'https://www.youtube.com/embed/eqCY9Mj-0zM'
  },
  {
    title: 'Vadain How to: wave gordijnen ophangen',
    src: 'https://www.youtube.com/embed/UWhbtsy_XtY'
  },
  {
    title: 'E-rails en stijlcombinaties',
    src: 'https://www.youtube.com/embed/LV064PTjgCM'
  },
  {
    title: 'De bediening van E-rails en E-vouwgordijnen',
    src: 'https://www.youtube.com/embed/go6zBYX02TI'
  },
  {
    title: 'De motoren van de E-rails collectie',
    src: 'https://www.youtube.com/embed/EnMAg0PywGQ'
  },
  {
    title: 'E-vouwgordijnen',
    src: 'https://www.youtube.com/embed/lqOXkAOKRWY'
  }
]

// pl downloads
export const PL_DOWNLOADS = {
  general: [
    {
      title: 'Ogólne Warunki Umów',
      url: '/documents/pl/general/Ogólne_Warunki_Umów_Vadain.pdf'
    },
    /*{
      title: 'Przewodnik produktowy 2023',
      url: '/documents/pl/general/Przewodnik_produktowy_VADAIN_2023.pdf'
    },*/
    {
      title: 'Przewodnik Vadain',
      url: '/documents/pl/general/Przewodnik_Vadain.pdf'
    },
    /*{
      title: 'Standardy tolerancji',
      url: '/documents/pl/general/Standardy_tolerancji_zaslon_i_firan_Vadain.pdf'
    },*/
    {
      title: 'Tkaniny wycofane Vadain',
      url: '/documents/pl/general/Tkaniny_wycofane_vadain.pdf'
    },
    {
      title: 'Tkaniny wycofane Vadain ELSE',
      url: '/documents/pl/general/Tkaniny_wycofane_vadain_else.pdf'
    },
    {
      title: 'Instrukcja prania zasłon',
      url: '/documents/pl/general/Instrukcja_prania_zaslon_Microflex.pdf'
    },
    {
      title: 'Instrukcja demontaż rolety',
      url: '/documents/pl/general/Demontaz_rolety_rzymskiej_VADAIN.pdf'
    },
    {
      title: 'Broszura Karnisze Asortyment',
      url: '/documents/pl/general/Broszura_Karnisze-Asortyment.pdf'
    },
    {
      title: 'Broszura Karnisze Inspiracje',
      url: '/documents/pl/general/Broszura_Karnisze_Vadain-Inspiracje.pdf'
    },
    {
      title: 'DECOBOOK - Vadain Symbiotic Bliss',
      url: '/documents/pl/general/DECOBOOK_Vadain_Symbiotic_Bliss.pdf'
    },
    {
      title: 'DECOBOOK - Vadain else Future Flash',
      url: '/documents/pl/general/DECOBOOK_Vadain_else_Future_Flash.pdf'
    },
    {
      title: 'DECOBOOK - bestsellery vadain else 2023',
      url: '/documents/pl/general/DECOBOOK_Bestsellery_vadain_else_2023.pdf'
    },
    {
      title: 'DECOBOOK - tkaniny welwetowe Vadain',
      url: '/documents/pl/general/DECOBOOK_Tkaniny_welwetowe_Vadain.pdf'
    },
    {
      title: 'DECOBOOK - vadain wiosna 2023',
      url: '/documents/pl/general/DECOBOOK_Vadain_wiosna_2023.pdf'
    },
    {
      title: 'Volant 0 - roleta rzymska Vadain',
      url: '/documents/pl/general/Volant_0_-_Roleta_rzymska_Vadain.pdf'
    }
  ],
  forms: [
    {
      title: 'Protokół reklamacyjny PDF',
      url: '/documents/pl/forms/Protokol_reklamacyjny.pdf'
    }
  ],
  [DOWNLOAD_CATEGORY_PRICE_LISTS]: [
    {
      title: 'Cennik Vadain - 2024',
      url: '/documents/pl/pricelists/CENNIK_VADAIN_2024.pdf'
    },
    {
      title: 'Cennik SMART Vadain - 2024',
      url: '/documents/pl/pricelists/CENNIK_VADAIN_SMART_2024.pdf'
    },
    {
      title: 'Cennik SMART Vadain - 2025',
      url: '/documents/pl/pricelists/CENNIK_VADAIN_SMART_2025.pdf'
    }
  ]
}

// OBI Polska downloads
export const OBI_POLSKA_DOWNLOADS = {
  general: [
    {
      title: 'Demontaż rolety rzymskiej',
      url: '/documents/obi polska/general/Demontaż_rolety_rzymskiej.pdf'
    },
    {
      title: 'Instrukcja prania',
      url: '/documents/obi polska/general/Instrukcja_prania_zasłon_Microflex.pdf'
    },
    {
      title: 'Roleta rzymska - jak prawidłowo mierzyć',
      url: '/documents/obi polska/general/ROLETA_RZYMSKA_jak_prawidłowo_mierzyć.pdf'
    },
    {
      title: 'Zasłony - jak prawidłowo mierzyć',
      url: '/documents/obi polska/general/ZASŁONY_jak_prawidłowo_mierzyć.pdf'
    },
    {
      title: 'Symbole konserwacji tkanin',
      url: '/documents/obi polska/general/Symbole_konserwacji_tkanin.png'
    },
    {
      title: 'Szczegółowe informacje o tkaninach',
      url: '/documents/obi polska/general/Szczegółowe_informacje_o_tkaninach.pdf'
    }
  ]
}

export const PL_VIDEOS = [
  { title: 'Najważniejsze cechy', src: 'https://www.youtube.com/embed/8KQV6YDh1QU' },
  { title: 'Filmy instruktażowe', src: 'https://www.youtube.com/embed/uxbuH9-OIc0' },
  {
    title: 'Uruchomienie i personalizacja',
    src: 'https://www.youtube.com/embed/ltfdVzvN5oo'
  },
  {
    title: 'Konfigurowanie zasłon',
    src: 'https://www.youtube.com/embed/bYHqPhTiJPY'
  },
  {
    title: 'Konfigurowanie rolety rzymskiej',
    src: 'https://www.youtube.com/embed/I6ilYPvYdxY'
  },
  {
    title: 'Zamawianie karnisza',
    src: 'https://www.youtube.com/embed/_TW0j2ZuJMI'
  },
  {
    title: 'Pomoc przy wprowadzaniu zamówień',
    src: 'https://www.youtube.com/embed/-gR7H1O7E7Y'
  },
  {
    title: 'Usługa zamawiania próbek',
    src: 'https://www.youtube.com/embed/439YdVwgIL8'
  }
]

export const DASHBOARD_IMAGE_NAMES = [
  'Anne_met_een_vadaingordijn',
  'hi_interconnectedcollection',
  'collection1',
  'collection2',
  'products',
  'sampleService',
  'quotes',
  'order',
  'faq',
  'curtainBreeze'
]
export const getDashboardImageMapping = (groupName) => {
  const imageMapping = {}
  for (const imageName of DASHBOARD_IMAGE_NAMES) {
    imageMapping[
      imageName
    ] = require(`@assets/dashboard/${groupName.toLowerCase()}/${imageName}.jpg`)
  }
  return imageMapping
}
export const AUSTRALIAN_DASHBOARD_IMAGES = getDashboardImageMapping(AUSTRALIAN_GROUP_NAME)
export const DEFAULT_DASHBOARD_IMAGES = getDashboardImageMapping(DEFAULT_GROUP_NAME)

export const DASHBOARD_IMAGE_MAPPING = {
  [DEFAULT_GROUP_NAME]: DEFAULT_DASHBOARD_IMAGES,
  [AUSTRALIAN_GROUP_NAME]: AUSTRALIAN_DASHBOARD_IMAGES
}

export const DOWNLOAD_MAPPING = {
  [DEFAULT_GROUP_NAME]: NL_DOWNLOADS,
  [POLISH_GROUP_NAME]: PL_DOWNLOADS,
  [ANWIS_GROUP_NAME]: PL_DOWNLOADS,
  [OBI_POLSKA_GROUP_NAME]: OBI_POLSKA_DOWNLOADS
}

export const COLLECTION_NAME_MAPPING = {
  [DEFAULT_GROUP_NAME]: 'Vadain',
  [OBI_POLSKA_GROUP_NAME]: 'OBI'
}

export const VIDEO_MAPPING = {
  [DEFAULT_GROUP_NAME]: NL_VIDEOS,
  [POLISH_GROUP_NAME]: PL_VIDEOS,
  [ANWIS_GROUP_NAME]: PL_VIDEOS
}

export const currencySymbolMap = {
  AED: 'د.إ',
  AFN: '؋',
  ALL: 'L',
  AMD: '֏',
  ANG: 'ƒ',
  AOA: 'Kz',
  ARS: '$',
  AUD: '$',
  AWG: 'ƒ',
  AZN: '₼',
  BAM: 'KM',
  BBD: '$',
  BDT: '৳',
  BGN: 'лв',
  BHD: '.د.ب',
  BIF: 'FBu',
  BMD: '$',
  BND: '$',
  BOB: '$b',
  BOV: 'BOV',
  BRL: 'R$',
  BSD: '$',
  BTC: '₿',
  BTN: 'Nu.',
  BWP: 'P',
  BYN: 'Br',
  BYR: 'Br',
  BZD: 'BZ$',
  CAD: '$',
  CDF: 'FC',
  CHE: 'CHE',
  CHF: 'CHF',
  CHW: 'CHW',
  CLF: 'CLF',
  CLP: '$',
  CNH: '¥',
  CNY: '¥',
  COP: '$',
  COU: 'COU',
  CRC: '₡',
  CUC: '$',
  CUP: '₱',
  CVE: '$',
  CZK: 'Kč',
  DJF: 'Fdj',
  DKK: 'kr',
  DOP: 'RD$',
  DZD: 'دج',
  EEK: 'kr',
  EGP: '£',
  ERN: 'Nfk',
  ETB: 'Br',
  ETH: 'Ξ',
  EUR: '€',
  FJD: '$',
  FKP: '£',
  GBP: '£',
  GEL: '₾',
  GGP: '£',
  GHC: '₵',
  GHS: 'GH₵',
  GIP: '£',
  GMD: 'D',
  GNF: 'FG',
  GTQ: 'Q',
  GYD: '$',
  HKD: '$',
  HNL: 'L',
  HRK: 'kn',
  HTG: 'G',
  HUF: 'Ft',
  IDR: 'Rp',
  ILS: '₪',
  IMP: '£',
  INR: '₹',
  IQD: 'ع.د',
  IRR: '﷼',
  ISK: 'kr',
  JEP: '£',
  JMD: 'J$',
  JOD: 'JD',
  JPY: '¥',
  KES: 'KSh',
  KGS: 'лв',
  KHR: '៛',
  KMF: 'CF',
  KPW: '₩',
  KRW: '₩',
  KWD: 'KD',
  KYD: '$',
  KZT: '₸',
  LAK: '₭',
  LBP: '£',
  LKR: '₨',
  LRD: '$',
  LSL: 'M',
  LTC: 'Ł',
  LTL: 'Lt',
  LVL: 'Ls',
  LYD: 'LD',
  MAD: 'MAD',
  MDL: 'lei',
  MGA: 'Ar',
  MKD: 'ден',
  MMK: 'K',
  MNT: '₮',
  MOP: 'MOP$',
  MRO: 'UM',
  MRU: 'UM',
  MUR: '₨',
  MVR: 'Rf',
  MWK: 'MK',
  MXN: '$',
  MXV: 'MXV',
  MYR: 'RM',
  MZN: 'MT',
  NAD: '$',
  NGN: '₦',
  NIO: 'C$',
  NOK: 'kr',
  NPR: '₨',
  NZD: '$',
  OMR: '﷼',
  PAB: 'B/.',
  PEN: 'S/.',
  PGK: 'K',
  PHP: '₱',
  PKR: '₨',
  PLN: 'PLN',
  PYG: 'Gs',
  QAR: '﷼',
  RMB: '￥',
  RON: 'lei',
  RSD: 'Дин.',
  RUB: '₽',
  RWF: 'R₣',
  SAR: '﷼',
  SBD: '$',
  SCR: '₨',
  SDG: 'ج.س.',
  SEK: 'kr',
  SGD: 'S$',
  SHP: '£',
  SLL: 'Le',
  SOS: 'S',
  SRD: '$',
  SSP: '£',
  STD: 'Db',
  STN: 'Db',
  SVC: '$',
  SYP: '£',
  SZL: 'E',
  THB: '฿',
  TJS: 'SM',
  TMT: 'T',
  TND: 'د.ت',
  TOP: 'T$',
  TRL: '₤',
  TRY: '₺',
  TTD: 'TT$',
  TVD: '$',
  TWD: 'NT$',
  TZS: 'TSh',
  UAH: '₴',
  UGX: 'USh',
  USD: '$',
  UYI: 'UYI',
  UYU: '$U',
  UYW: 'UYW',
  UZS: 'лв',
  VEF: 'Bs',
  VES: 'Bs.S',
  VND: '₫',
  VUV: 'VT',
  WST: 'WS$',
  XAF: 'FCFA',
  XBT: 'Ƀ',
  XCD: '$',
  XOF: 'CFA',
  XPF: '₣',
  XSU: 'Sucre',
  XUA: 'XUA',
  YER: '﷼',
  ZAR: 'R',
  ZMW: 'ZK',
  ZWD: 'Z$',
  ZWL: '$'
}

export const SHOP_SETTINGS_DEFAULT = {
  logo: require('@/assets/Vadain_logo_fc.svg'),
  primaryColor: '#b6735c'
}

export const SHOP_SETTINGS_TEST_PROJ = {
  logo: require('@/assets/Vadain_logo.svg'),
  primaryColor: '#b6735c'
}

export const DEFAULT_SHOP = 'default'
export const NEW_PROJ_SHOP = 'newProj'
